import React from 'react'
const FirmaBilgisi = () => {
  return (
    <div>
      <strong>İkitelli VD. V.No:</strong>&nbsp;31198823454
      <br />
      <strong>Mersis No:</strong>&nbsp;3119882345400001
      <br />
      <strong>Ticaret Sicil No:</strong>&nbsp;102885-5
    </div>
  )
}
export default FirmaBilgisi
