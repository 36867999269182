import React, { createContext, useReducer } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Reducer from './Reducer'
const initialState = {
  loading: false,
  sbOpen: false,
  theme: {
    primary: '#9575cd',
    primaryDark: '#7e57c2',
    primaryLight: '#b085f5',
    secondary: '#ff9100',
    secondaryDark: '#ff6d00',
    secondaryLight: '#ffc246',
    warn: '#ef5350',
    warnDark: '#f44336',
    warnLight: '#ff867c',
  },
  images: [],
}
export const Context = createContext(initialState)
const Store = ({ children }) => {
  const { allImageSharp } = useStaticQuery(
    graphql`
      query {
        allImageSharp {
          nodes {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            parent {
              ... on File {
                id
                name
                relativePath
                relativeDirectory
              }
            }
            xs: fixed(width: 310, toFormat: WEBP) {
              src
            }

            sm: fixed(width: 450, toFormat: WEBP) {
              src
            }

            md: fixed(width: 760, toFormat: WEBP) {
              src
            }

            lg: fixed(width: 580, toFormat: WEBP) {
              src
            }
            thumb: fixed(width: 360, toFormat: WEBP) {
              src
            }
            original {
              src
            }
          }
        }
      }
    `,
  )

  const [state, dispatch] = useReducer(Reducer, {
    ...initialState,
    images: allImageSharp.nodes,
  })

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
}
export default Store
