import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'none',
  },
}))
const OutLink = ({ target, href, style, children }) => {
  const classes = useStyles()
  return (
    <a className={classes.root} target={target || '_blank'} rel='noopener noreferrer' style={style} href={href}>
      {children}
    </a>
  )
}
export default OutLink
