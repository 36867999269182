export const SideBarData = [
  { title: 'Hasır Tel', url: '/hasir-tel' },
  { title: 'Paslanmaz Hasır Tel', url: '/paslanmaz-hasir-tel' },
  {
    title: '304 kalite paslanmaz hasır tel',
    url: '/304-kalite-paslanmaz-hasir-tel',
  },
  {
    title: '316 kalite paslanmaz hasır tel',
    url: '/316-kalite-paslanmaz-hasir-tel',
  },
  { title: 'Galvaniz Hasır Tel', url: '/galvaniz-hasir-tel' },
  { title: 'Çelik Hasır Tel İmalatı', url: '/celik-hasir-tel-imalati' },
  {
    title: 'Nervürlü Çelik Hasır Tel İmalatı',
    url: '/nervurlu-celik-hasir-tel-imalati',
  },
  { title: 'Çit Hasır Teli İmalatı', url: '/cit-hasir-teli-imalati' },
  {
    title: 'Asansör Seperatör Hasır Tel İmalatı',
    url: '/asansor-seperator-teli',
  },
  { title: 'Fens Teli İmalatı', url: '/fens-teli-imalati' },
  { title: 'Punta Kaynaklı Hasır Tel', url: '/punta-kaynakli-hasir-tel' },
  {
    title: 'Güvenlik Bariyeri Hasır Teli İmalatı',
    url: '/guvenlik-bariyeri-hasir-teli-imalati',
  },
  { title: 'Kafes Hasır Teli İmalatı', url: '/kafes-hasir-teli-imalati' },
  { title: 'İthal Punta Kaynaklı Teller', url: '/ithal-punta-kaynakli-teller' },
  { title: 'Kafes Tel İmalatı', url: '/kafes-tel-imalati' },
  { title: 'Panel Tel İmalatı', url: '/panel-tel-imalati' },
]
