const Reducer = (state, action) => {
  switch (action.type) {
    case "OPEN_SIDEBAR":
      return { ...state, sbOpen: true }
    case "CLOSE_SIDEBAR":
      return { ...state, sbOpen: false }
    case "TOGGLE_SIDEBAR":
      return { ...state, sbOpen: !state.sbOpen }

    default:
      return state
  }
}
export default Reducer
