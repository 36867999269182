/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './styles/index.scss'
import 'react-alice-carousel/lib/alice-carousel.css'
import 'react-image-lightbox/style.css'
import muiRootWrapper from './mui-root-wrapper'
export const wrapRootElement = muiRootWrapper

// exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//   const { pathname } = location
//   // list of routes for the scroll-to-top-hook
//   const skipScrollToTopRoutes = [`/dont-scroll-top`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (skipScrollToTopRoutes.indexOf(pathname) === -1) {
//     const mainElement = document.getElementById('main-container')
//     mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
//     // window.scrollTo(0, 0)
//   }

//   return false
// }
