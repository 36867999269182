import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Store from '../store'
import MainContainer from './MainContainer'
const Layout = ({ children }) => {
  return (
    <Store>
      <CssBaseline>
        <MainContainer>{children}</MainContainer>
      </CssBaseline>
    </Store>
  )
}
export default Layout
