import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import PhotoIcon from '@material-ui/icons/Photo'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
export const navData = [
  { title: 'Ana Sayfa', url: '/', icon: <HomeIcon /> },
  { title: 'Ürün Resimleri', url: '/galeri', icon: <PhotoIcon /> },
  { title: 'Hakkımızda', url: '/hakkimizda', icon: <ContactSupportIcon /> },
  { title: 'Banka Bilgileri', url: '/banka-bilgileri', icon: <AccountBalanceIcon /> },
  { title: 'İletişim', url: '/iletisim', icon: <PermContactCalendarIcon /> },
]
