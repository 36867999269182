import React from 'react'
import { Link } from 'gatsby'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/core/styles'
import { SideBarData } from './sidebarData'
import { navData } from '../Navbar/navData'
import NavLogo from '../Navbar/NavLogo'

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    padding: '10px 0 20px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    '& .MuiListItemIcon-root': {
      minWidth: '24px',
      color: theme.palette.error.main,
    },
    '&.MuiListItem-gutters ': {
      paddingLeft: '5px',
      paddingRight: '10px',
    },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.5em',
      },
    },
  },
  listItemMobil: {
    '& .MuiListItemIcon-root': {
      minWidth: '24px',
      color: theme.palette.error.main,
    },
    '& svg': {
      width: '1.1rem',
      height: 'auto',
    },
    '&.MuiListItem-gutters ': {
      paddingLeft: '5px',
      paddingRight: '10px',
    },
  },
}))
const SideBarListMobile = ({ toggleSlider }) => {
  const classes = useStyles()
  return (
    <List dense>
      {navData.map((data, index) => (
        <ListItem component={Link} to={data.url} button key={index} className={classes.listItemMobil} onClick={toggleSlider}>
          <ListItemIcon>{data.icon}</ListItemIcon>
          <ListItemText primary={data.title} />
        </ListItem>
      ))}
    </List>
  )
}
const SideBarList = ({ toggleSlider }) => {
  const classes = useStyles()
  return (
    <List dense>
      {SideBarData.map((data, index) => (
        <ListItem component={Link} to={data.url} button key={index} className={classes.listItem} onClick={toggleSlider}>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={data.title} />
        </ListItem>
      ))}
    </List>
  )
}
const Sidebar = ({ sideOpen, toggle }) => {
  const classes = useStyles()

  return (
    <>
      <Hidden mdUp implementation='js'>
        <Drawer open={sideOpen} variant='temporary' onClose={toggle}>
          <div>
            <div className={classes.logoWrapper}>
              <NavLogo />
            </div>
            <SideBarListMobile toggleSlider={toggle} />
            <Divider />
            <SideBarList toggleSlider={toggle} />
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <div>
          <SideBarList />
        </div>
      </Hidden>
    </>
  )
}
export default Sidebar
