import React from 'react'
import Grid from '@material-ui/core/Grid'
import YouTubeIcon from '@material-ui/icons/YouTube'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import OutLink from '../../components/Typography/OutLink'
const SocialLinks = () => {
  return (
    <Grid container alignItems='center' justify='center' spacing={1}>
      <Grid item>
        <OutLink style={{ color: '#ff0000' }} href='https://www.youtube.com/watch?v=rZpN0Uyqp_o'>
          <YouTubeIcon fontSize='large' />
        </OutLink>
      </Grid>
      <Grid item>
        <OutLink style={{ color: '#3b5998' }} href='https://www.facebook.com/erezkaynak'>
          <FacebookIcon fontSize='large' />
        </OutLink>
      </Grid>
      <Grid item>
        <OutLink style={{ color: '#bc2a8d' }} href='https://www.instagram.com/erezkaynak/'>
          <InstagramIcon fontSize='large' />
        </OutLink>
      </Grid>
      <Grid item>
        <OutLink
          style={{ color: '#dd4b39' }}
          href={`https://www.google.com/search?rlz=1C1OKWM_trTR856TR856&amp;ei=XxRuXc6-FJLZmwXK8KH4Cg&amp;q=Erez+Kaynak+%C3%87elik+Has%C4%B1r+Tel+ve+Filtre+Elek+%C4%B0malat%C3%A7%C4%B1s%C4%B1&amp;oq=Erez+Kaynak+%C3%87elik+Has%C4%B1r+Tel+ve+Filtre+Elek+%C4%B0malat%C3%A7%C4%B1s%C4%B1&amp;gs_l=psy-ab.3..38.742.742..962...0.2..0.126.126.0j1......0....1..gws-wiz.......0i71.QdESX30aPi4&amp;ved=0ahUKEwiO6u7yjrTkAhWS7KYKHUp4CK8Q4dUDCAo&amp;uact=5`}
        >
          <FindInPageIcon fontSize='large' />
        </OutLink>
      </Grid>
    </Grid>
  )
}
export default SocialLinks
