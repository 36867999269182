import { createMuiTheme, colors } from '@material-ui/core'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.purple[500],
    },
    secondary: {
      main: colors.red[400],
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      // default: `#f2f6fa`,
      default: '#ffffff',
    },
  },
})

export default theme
